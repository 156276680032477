export default [
  {
    title: 'Home',
    route: 'home',
    icon: 'HomeIcon',
  },
  {
    title: 'Trips',
    route: 'trips',
    icon: 'SunIcon',
  },
  {
    title: 'Emails',
    route: 'emails',
    icon: 'MailIcon',
  },
  {
    title: 'Users',
    route: 'users',
    icon: 'UsersIcon',
  },
  {
    title: 'Vendors',
    route: 'vendors',
    icon: 'ListIcon',
  },
  {
    title: 'Domains',
    route: 'domains',
    icon: 'CloudIcon',
  },
  {
    title: 'Custom Trips',
    route: 'custom-trips',
    icon: 'ListIcon',
  },
]
